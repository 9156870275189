<template>
    <v-row align="center" class="pe-5" justify="end">
        <v-btn :disabled="activatingForm" :loading="savingAsDraft" class="me-5" color="primary" outlined
               @click="saveAsDraft">
            Save as draft
        </v-btn>
        <v-btn :disabled="savingAsDraft" :loading="activatingForm" color="primary" @click="saveAndActivate">
            Save and activate
        </v-btn>
    </v-row>
</template>

<script>
export default {
    name: "KurccFormSubmitButtons",
    props: {
        selectedCategoriesToAssignTo: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            activatingForm: false,
            savingAsDraft: false
        }
    },
    computed: {
        form() {
            return this.$store.state.forms.form
        },
        isNewForm() {
            return this.$lodash.isNull(this.form.id)
        },
        categoryIds() {
            return this.selectedCategoriesToAssignTo.map(val => val.id)
        }
    },
    methods: {
        saveAndActivate() {
            this.activatingForm = true
            if (this.isNewForm) {
                this.createForm(1, 0)
            } else {
                this.updateForm(1, 0)
            }
        },
        saveAsDraft() {
            this.savingAsDraft = true
            if (this.isNewForm) {
                this.createForm(0, 1)
            } else {
                this.updateForm(0, 1)
            }
        },
        updateForm(enabled, isDraft) {
            this.$store.dispatch('updateForm', {
                id: this.form.id,
                data: this.form,
                enabled: enabled,
                is_draft: isDraft,
                category_ids: this.categoryIds
            }).then(() => {
                this.$router.push({name: 'forms'})
            }).finally(() => {
                this.activatingForm = false
                this.savingAsDraft = false
            })
        },
        createForm(enabled, isDraft) {
            this.$store.dispatch('createForm', {
                enabled: enabled,
                is_draft: isDraft,
                category_ids: this.categoryIds
            }).then(() => {
                this.$router.push({name: 'forms'})
            }).finally(() => {
                this.activatingForm = false
                this.savingAsDraft = false
            })
        }
    }
}
</script>
